<template>
  <div>
    <b-alert>Sie werden abgemeldet...</b-alert>
  </div>
</template>

<script>

export default {
  name: 'Logout',
  components: {},
  metaInfo: {
    title: 'Abmelden'
  },
  created() {
    this.$store.dispatch("auth/logout");
    window.location.href = 'fondsliste';
  },
}
</script>
